/* .feature-page{

} */
.feature-page .section-one {
  margin: 60px 0;
}
.feature-page .section-one .feature-header {
  text-align: center;
  margin: 60px auto;
}

.feature-page .section-one .feature-header h5 {
  color: #0c5adb;
  font-size: 35px;
  font-weight: 800;
}

.feature-page .section-one .feature-header p {
  font-size: 25px;
  font-weight: 800;
  color: #232323;
}

.feature-page .section-one .feature-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.feature-page .section-one .feature-cards div {
  list-style: none;
  margin: 20px;
  padding: 30px 0px 10px 0px;
  width: 25%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0c5adb;
}

.feature-page .section-one .feature-cards div p {
  font-size: 20px;
  font-weight: 800;
  color: white;
  text-align: center;
}

.feature-page .section-one .feature-cards div img {
  width: 30%;
  margin: 20px;
}

.feature-page .section-one .feature-cards .feature-card {
  border: 1px solid #eeeeee;
  padding: 20px;
  text-align: center;
  margin: 10px;
  transition: 0.3s;
}
.feature-page .section-one .feature-cards .feature-card:hover {
  transform: scale(1.1);
  z-index: 1;
}
.feature-page .section-one .feature-cards .feature-card .feature-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 20px 18px;
  border: 1px solid #eeeeee;
}

.feature-page .section-one .feature-cards .feature-card .feature-content span {
  margin-top: 20px;
}

@media screen and (max-width: 1200px) {
  .feature-page .section-one .feature-cards div {
    width: 45%;
    margin: 10px;
  }
}
@media screen and (max-width: 800px) {
  .feature-page .section-one .feature-cards div {
    width: 100%;
  }
}
