.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 16px 0;
  background-color: white;
  z-index: 100;
  transition: 0.3s;
}
.scrolled {
  background-color: #462aff;
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}
.header .header-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

/* f */

.header .header-container .contact p {
  color: #462aff;
}

.header.scrolled .header-container .contact p {
  color: white;
}

.header .header-container .header-icon img {
  width: 35%;
  height: auto;
}

.header .header-mobile-container .header-icon img {
  width: 45%;
  height: auto;
}

.header .header-container .navbar-list {
  display: flex;
  align-items: center;
  margin: 0px auto 0px 40px;
}
.header .header-container .navbar-list ul {
  display: flex;
}
.header .header-container .navbar-list ul li {
  list-style: none;
  margin-left: 16px;
}

.header .header-container .navbar-list ul li a {
  text-decoration: none;
  color: #462aff;
  font-size: 17px;
  font-weight: 400;
}

.scrolled .header-container .navbar-list ul li a {
  color: white;
}

.header .header-container .navbar-list .get-started {
  margin-left: 16px;
  color: white;
  background-color: #0c5adb;
  border-radius: 5px;
  cursor: pointer;
  width: 147px;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border: 2px solid #0c5adb;
}
.scrolled .header-container .navbar-list .get-started {
  color: #0c5adb;
  border: 2px solid white;
  background-color: white;
}
.header .header-mobile-container {
  display: none;
  justify-content: space-between;
}
.header .header-mobile-container h2 {
  color: white;
  font-size: 22px;
}
.header .header-mobile-container .menu-icon {
  cursor: pointer;
  display: flex;
}
.navbar-mobile {
  background-color: white;
  margin-top: 60px;
  box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 100;
  height: 100%;
  background-color: #0762f8;
}


.navbar-mobile.active {
  right: 0;
}

.navbar-mobile ul li {
  text-decoration: none;
  list-style: none;
  padding: 16px 0;
  cursor: pointer;
  text-transform: uppercase;
}
.navbar-mobile ul li:first-child {
  border: none;
}
.navbar-mobile ul li :hover {
  /* color: #0c5adb; */
  color: #fab018;
}
.navbar-mobile ul li a {
  text-decoration: none;
  /* color: #444; */
  color: white;
  padding: 0 60px;
  transition: 0.3s;
}

@media screen and (max-width: 1200px) {
  .header {
    background-color: #0762f8;
  }

  .header .header-container {
    display: none;
  }
  .header .header-mobile-container {
    display: flex;
  }

  .header .header-mobile-container .header-icon img {
    width: 30%;
  }
}
