.demo-page {
  margin-top: 30px;
}
.demo-page .section-one {
  background: linear-gradient(to top, #f4f5f9, #f4f5f9, #fbfbfd, #fff, #fff);
  padding: 60px 0px;
}

@media(max-width:768px){
  .demo-page .section-one{
    padding: 0px 0px;
  }
}

.demo-page .section-one .demo-header {
  text-align: center;
}
.demo-page .section-one .demo-header h2 {
  margin: 60px auto;
  font-size: 40px;
  font-weight: 700;
}
.demo-page .section-one .demo-header ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 60px 0;
}

.demo-page .section-one .demo-header ul li {
  list-style: none;
  margin: 0px 10px;
  font-size: 15px;
  color: #828282;
  font-size: 700;
  cursor: pointer;
}

.demo-page .section-one .demo-header ul li:hover {
  color: black;
}

.demo-page .section-one .demo-cards {
  display: flex;
  justify-content: space-between;
  /* display: grid;
  grid-template-columns: repeat(3, auto);
  text-align: center; */
}

.demo-page .section-one .demo-cards .card-container {
  margin: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .mySwiper{
  width: 100%;
  height: 100%;
  border: 1px solid red;
  display: flex;
  flex-direction: column;
} */

.demo-page .section-one .demo-cards .card-container img {
  width: 100%;
  /* width: 60%; */
  border-radius: 10px;
  height: 60;
  object-fit: contain;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.demo-page .section-one .demo-cards .card-container p {
  font-size: 24px;
  text-align: center;
  color: #232323;
  margin: 10px 0;
  /* width: 60%; */
  /* margin: 20px auto 5px auto; */
}
.demo-page .section-one .demo-cards .card-container span {
  color: #828282;
  font-size: 16px;
  margin-top: 10px;
  display: inline-block;
  width: 50%;
}

.demo-page .section-two {
  margin: 80px 0;
}

.demo-page .section-two .design-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.demo-page .section-two .design-container .design-context {
  background-color: white;
  text-align: center;
}
.demo-page .section-two .design-container .design-context h4 {
  color: #0c5adb;
  font-size: 35px;
  font-weight: 800;
  margin-bottom: 10px;
}

.demo-page .section-two .design-container .design-context h2 {
  color: #232323;
  font-size: 40px;
}
.demo-page .section-two .design-container .design-context .design-content {
  margin-top: 40px;
}
.demo-page .section-two .design-container .design-context .design-content h5 {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 15px;
}
.demo-page .section-two .design-container .design-context .design-content p {
  color: #828282;
  font-size: 16px;
  font-weight: 400;
}

.demo-page .section-two .design-container .design-img {
  display: flex;
  justify-content: center;
  width: 50%;
}

.demo-page .section-two .design-container .design-img img {
  margin: auto;
  width: 70%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media screen and (max-width: 1200px) {
  .demo-page .section-one .demo-header h2 {
    font-size: 36px;
  }

  /* .demo-page .section-one .demo-cards {
    grid-template-columns: repeat(2, auto);
  } */
  .demo-page .section-one .demo-cards .card-container p {
    font-size: 20px;
  }
  .demo-page .section-one .demo-cards .card-container img {
    width: 150px;
    /* height: 300px; */
  }

  .demo-page .section-two .design-container {
    flex-direction: column;
    /* align-items: flex-start; */
  }
  .demo-page .section-two .design-container .design-img {
    width: 100%;
    margin-top: 20px;
  }
  .demo-page .section-two .design-container .design-context .design-content h5 {
    font-size: 18px;
  }
}

@media screen and (max-width: 800px) {
  .demo-page .section-one .demo-header h2 {
    font-size: 30px;
  }
  .demo-page .section-one .demo-cards {
    grid-template-columns: repeat(1, auto);
    justify-content: center;
  }
  .demo-page .section-two .design-container .design-context h2 {
    font-size: 30px;
  }

  .demo-page .section-one .demo-cards .card-container img{
    width: 100px;
  }
  .demo-page .section-one .demo-cards .card-container p{
    font-size: 14px;
  }
 
}
