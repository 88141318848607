*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Archivo', sans-serif;
}

.container{
  padding: 0 60px;
  /* width: 100%; */
}

@media screen and (max-width:600px) {
  .container{
    padding: 0 16px;
  }
}